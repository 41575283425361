import React from "react";
import { connect } from "react-redux";
import { Dimmer, Grid, Loader } from "semantic-ui-react";
import { Auth, Hub } from "aws-amplify";
import { history } from "../../routers/AppRouters";
import { startLogin, startLogout } from "../../store/actions/shared/auth";

class AuthContainer extends React.Component {
  constructor(props) {
    super(props);
    Hub.listen("auth", this, "AuthContainer");
  }

  onHubCapsule = capsule => {
    //console.log(Auth);
    Auth.currentAuthenticatedUser()
      .then(user => {
        //console.log("AuthContainer", user);
        this.props.startLogin(user);
        history.push("/");
      })
      .catch(err => {
        //console.log("AuthContainer", err);
        this.props.startLogout();
        history.push("/");
      });
  };

  render() {
    return (
      <Grid
        container
        columns={4}
        textAlign="center"
        style={{
          paddingTop: "80px",
          height: "calc(100% - 1px)"
        }}
      >
        <Dimmer active inverted>
          <Loader indeterminate inverted content="Loading" size="huge"/>
        </Dimmer>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  state: state,
  auth: state.authReducer.auth,
  authState: state.authReducer.state
});

const mapDispatchToProps = dispatch => ({
  startLogin: user => dispatch(startLogin(user)),
  startLogout: () => dispatch(startLogout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthContainer);
