
import _ from 'lodash';
import React, { useState } from 'react';
import {
  Form,
  Modal,
  Tab,
  Table,
  TabPane
} from 'semantic-ui-react';
import { monthStrAbbr } from '../../../utils/formUtils';
import { getBudgetDetails, getBudgetDetailsTotals, getDatesAndYears, limitDigits } from '../../../utils/projectPlanningUtils';

const getInputStyle = ({ isCutoffDisabled, isDisabled }) => ({ padding: 0, background: isCutoffDisabled ? "#989FCE" : isDisabled ? 'rgba(40,40,40,.3)' : '' })
const getTableCellStyle = ({ isCutoffDisabled, isDisabled }) => ({ background: isCutoffDisabled ? "#989FCE" : isDisabled ? 'rgba(40,40,40,.3)' : '' })

function NextCostsAndRevenuesT0Modal(props) {
  const { isOpen, onClose, budget, setValues, figures, actual_forecast_details, superCutoffDate, isReadOnly, theOneRing } = props

  if (actual_forecast_details === undefined || actual_forecast_details.details === undefined) {
    return null;
  }

  const { startDate, endDate, numberOfYears, years } = getDatesAndYears(props.budget)

  const budget_details = getBudgetDetails(figures, actual_forecast_details.details, props.budget.project_type)

  // BUG FIX AEP-3980 FIX - - - 
  // https://avvale-support.atlassian.net/browse/AEP-3980

  // Verifica se utilizzare actual_forecast_details.details o budget_details
  const detailsToUse = budget_details.map((item) => {
    const ringStatus = theOneRing(item.year, item.month);
    return ringStatus.isCutoffDisabled || ringStatus.isDisabled
      ? actual_forecast_details.details.find(detail => detail.year === item.year && detail.month === item.month) || item
      : item;
  });

  // Calcolo dei totali basato sui dettagli scelti
  const totals = getBudgetDetailsTotals(detailsToUse);
  const totalsMarginOnly = getBudgetDetailsTotals(budget_details);
  // BUG FIX AEP-3980 FIX - - - 

  const [activeIndex, setActiveIndex] = useState(0);

  const onOuterTabChange = (_event, data) => {
    setActiveIndex(data.activeIndex)
    // console.log('onOuterTabChange', data.activeIndex);
  }

  console.log('details: ', actual_forecast_details.details);

  return (
    <Modal
      size="large"
      open={isOpen}
      closeOnEscape={true}
      closeOnDimmerClick={true}
      onClose={onClose}
      closeIcon
      dimmer="blurring"
    >
      <Modal.Header>Costs & Revenues</Modal.Header>
      <Modal.Content scrolling>
        <Form>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem', float: 'right' }}>
            <span>PFE Revenues <b>{actual_forecast_details.pfe_revenues}</b></span>
            <span>PFE Bill Exp <b>{actual_forecast_details.pfe_billable_expenses}</b></span>
          </div>
          <Tab onTabChange={onOuterTabChange}
            panes={
              years.map((year, index) => ({
                menuItem: {
                  content: year.toString(),
                  className: activeIndex === index ? 'menu-item-active' : 'menu-item'
                },
                render: () => (
                  <TabPane>
                    <Table celled definition>
                      <Table.Header fullWidth>
                        <Table.Row>
                          <Table.HeaderCell />
                          <Table.HeaderCell>Total</Table.HeaderCell>
                          {monthStrAbbr.map((monthName, monthIndex) => (<Table.HeaderCell key={monthIndex}>{monthName}</Table.HeaderCell>))}
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>Days</Table.Cell>
                          <Table.Cell>{totals.days.toFixed(2)}</Table.Cell>
                          {budget_details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
                            const cellData = theOneRing(item.year, item.month).isCutoffDisabled || theOneRing(item.year, item.month).isDisabled
                              ? actual_forecast_details.details.find(detail => detail.year === item.year && detail.month === item.month)
                              : item;
                            return (
                              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month))} key={index}>
                                {cellData ? cellData.days.toFixed(2) : <></>}
                              </Table.Cell>
                            )
                          })}
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Total Revenues</Table.Cell>
                          <Table.Cell>{totals.totalRevenues.toFixed(2)}</Table.Cell>
                          {budget_details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
                            const cellData = theOneRing(item.year, item.month).isCutoffDisabled || theOneRing(item.year, item.month).isDisabled
                              ? actual_forecast_details.details.find(detail => detail.year === item.year && detail.month === item.month)
                              : item;
                            return (
                              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month))} key={index}>
                                {cellData ? cellData.total_revenues.toFixed(2) : <></>}
                              </Table.Cell>
                            )
                          })}
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Revenues</Table.Cell>
                          <Table.Cell>{totals.revenues.toFixed(2)}</Table.Cell>
                          {budget_details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
                            <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month))} key={index}>
                              {props.budget.project_type === "Time Material" ?
                                item.revenues :
                                <input
                                  style={getInputStyle(theOneRing(item.year, item.month))}
                                  disabled={theOneRing(item.year, item.month).isDisabled || isReadOnly}
                                  type="number"
                                  value={item.revenues}
                                  onChange={(event) => {
                                    event.persist();
                                    if (event.target) {
                                      props.setValues(item.year, item.month, {
                                        revenues: limitDigits(event.target.value)
                                      })
                                    }
                                  }}
                                />
                              }
                            </Table.Cell>
                          ))}
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Billable Expenses</Table.Cell>
                          <Table.Cell>{totals.billableExpenses.toFixed(2)}</Table.Cell>
                          {budget_details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
                            <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month))} key={index}>
                              <input
                                style={getInputStyle(theOneRing(item.year, item.month))}
                                disabled={theOneRing(item.year, item.month).isDisabled || isReadOnly}
                                type="number"
                                value={item.billable_expenses}
                                onChange={(event) => {
                                  event.persist();
                                  if (event.target) {
                                    props.setValues(item.year, item.month, {
                                      billable_expenses: limitDigits(event.target.value)
                                    })
                                  }
                                }}
                              />
                            </Table.Cell>
                          ))}
                        </Table.Row>
                        {/* FIX 70 Campo total cost mancante: il campo che fa la somma di cost + expenses non è presente nella sezione Costs & Reveneus come da analisi. */}
                        <Table.Row>
                          <Table.Cell>Total Costs</Table.Cell>
                          <Table.Cell>{totals.totalCosts.toFixed(2)}</Table.Cell>
                          {budget_details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
                            const cellData = theOneRing(item.year, item.month).isCutoffDisabled || theOneRing(item.year, item.month).isDisabled
                              ? actual_forecast_details.details.find(detail => detail.year === item.year && detail.month === item.month)
                              : item;
                            return (
                              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month))} key={index}>
                                {cellData ? cellData.total_cost.toFixed(2) : <></>}
                              </Table.Cell>
                            )
                          })}
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Costs</Table.Cell>
                          <Table.Cell>{totals.costs.toFixed(2)}</Table.Cell>
                          {budget_details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => {
                            const cellData = theOneRing(item.year, item.month).isCutoffDisabled || theOneRing(item.year, item.month).isDisabled
                              ? actual_forecast_details.details.find(detail => detail.year === item.year && detail.month === item.month)
                              : item;
                            return (
                              <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month))} key={index}>
                                {cellData ? cellData.cost.toFixed(2) : <></>}
                              </Table.Cell>
                            )
                          })}
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Expenses</Table.Cell>
                          <Table.Cell>{totals.expenses.toFixed(2)}</Table.Cell>
                          {budget_details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
                            <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month))} key={index}>
                              <input
                                style={getInputStyle(theOneRing(item.year, item.month))}
                                disabled={theOneRing(item.year, item.month).isDisabled || isReadOnly}
                                type="number"
                                value={item.expenses}
                                onChange={(event) => {
                                  event.persist();
                                  if (event.target) {
                                    props.setValues(item.year, item.month, {
                                      expenses: limitDigits(event.target.value)
                                    })
                                  }
                                }}
                              />
                            </Table.Cell>
                          ))}
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Delta Revenues</Table.Cell>
                          <Table.Cell>{totals.deltaRevenues.toFixed(2)}</Table.Cell>
                          {budget_details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
                            <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month))} key={index}>
                              <input
                                style={getInputStyle(theOneRing(item.year, item.month))}
                                disabled={theOneRing(item.year, item.month).isDisabled || isReadOnly}
                                type="number"
                                value={item.delta_adj}
                                onChange={(event) => {
                                  event.persist();
                                  if (event.target) {
                                    props.setValues(item.year, item.month, {
                                      delta_adj: limitDigits(event.target.value)
                                    })
                                  }
                                }}
                              />
                            </Table.Cell>
                          ))}
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Delta Billable Expenses</Table.Cell>
                          <Table.Cell>{totals.deltaBillableExpenses.toFixed(2)}</Table.Cell>
                          {budget_details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
                            <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month))} key={index}>
                              <input
                                style={getInputStyle(theOneRing(item.year, item.month))}
                                disabled={theOneRing(item.year, item.month).isDisabled || isReadOnly}
                                type="number"
                                value={item.delta_bill_exp}
                                onChange={(event) => {
                                  event.persist();
                                  if (event.target) {
                                    props.setValues(item.year, item.month, {
                                      delta_bill_exp: limitDigits(event.target.value)
                                    })
                                  }
                                }}
                              />
                            </Table.Cell>
                          ))}
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Margin</Table.Cell>
                          {/* BUG controllo NaN */}
                          <Table.Cell>{isNaN(totalsMarginOnly.margin) ? (0).toFixed(2) : totalsMarginOnly.margin.toFixed(2)}</Table.Cell>
                          {budget_details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
                            <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month))} key={index}>
                              {item.margin.toFixed(2)}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Margin %</Table.Cell>
                          <Table.Cell>{isNaN(totalsMarginOnly.marginPercentage) ? (0).toFixed(2) : totalsMarginOnly.marginPercentage.toFixed(2)}</Table.Cell>
                          {budget_details.filter(h => h.year === year).sort((a, b) => a.month - b.month).map((item, index) => (
                            <Table.Cell style={getTableCellStyle(theOneRing(item.year, item.month))} key={index}>
                              {item.margin_percentage.toFixed(2)}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </TabPane>
                )
              }))
            }
          />
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default NextCostsAndRevenuesT0Modal;
